import React from "react"
import "./PhotoLink.css"

export default function PhotoLink({ name, image, link }) {
  return (
    <div className="photo-link-container">
      <a href={link} style={{ display: "block", position: "relative" }}>
        <div className="photo-link-overlay">{name}</div>
        <img className="photo-link" src={image} alt={name} />
      </a>
    </div>
  )
}
