import React from "react"
import Layout from "../../components/layout/Layout"
import PhotoLink from "../../components/photolink/PhotoLink"
import "./index.css"

const SUB_SECTIONS = [
  {
    name: "Birlanagar",
    image: "/images/birlanagar/1.jpeg",
    link: "/photo-stories/birlanagar",
  },
  {
    name: "Musahar",
    image: "/images/musahar/1.jpeg",
    link: "/photo-stories/musahar",
  },
]

export default function Documentary() {
  return (
    <Layout title="Photo Stories" heading="Photo Stories">
      <div className="photo-links-container">
        {SUB_SECTIONS.map(section => (
          <div key={section.name} className="photo-link-wrapper">
            <PhotoLink {...section} />
          </div>
        ))}
      </div>
    </Layout>
  )
}
